<template>
  <v-app>
    <Header title="Kennwort ändern" backLink="/" />
    <v-main>
      <v-card class="mx-auto mt-12" max-width="400" elevation="4">
        <v-form ref="formKennwort" @submit.prevent="onSubmit(old_password, password, reply_password);">
          <v-card-text>
            <v-text-field
              v-model="old_password"
              id="old_password"
              label="altes Kennwort"
              name="old_password"
              prepend-icon="lock"
              type="password"
              :rules="[rules.required]"

            ></v-text-field>

            <v-text-field
              v-model="password"
              id="password"
              label="neues Kennwort"
              name="password"
              prepend-icon="lock"
              type="password"
              :rules="[rules.required]"
            ></v-text-field>

            <v-text-field
              v-model="reply_password"
              id="reply_password"
              label="neues Kennwort wiederholen"
              name="reply_password"
              prepend-icon="lock"
              type="password"
              :rules="[rules.required, rules.password]"
            ></v-text-field>
          </v-card-text>
          <v-card-actions>
            <v-btn text type="submit">Kennwort ändern</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-main>
    <Footer />
    <AlertDialog />
  </v-app>
</template>

<script>

import { KENNWORT  } from "@/store/actions.type";
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import AlertDialog from "@/components/AlertDialog.vue";

export default {
  name: "Kennwort",
  components: {
    Header,
    Footer, 
    AlertDialog
  },  
  data() {
    return {
      dialog: false,
      old_password: null,
      password: null,
      reply_password: null,
      rules: {
          required: value => !!value || 'Kennwort muss gefüllt sein!',
          password: value => value == this.password || 'neues Kennwort und Kennwort wiederholen stimmen nicht überein!',
          email: value => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            return pattern.test(value) || 'Invalid e-mail.'
          },
        },
    };
  },
  methods: {

    onSubmit(old_password, password, reply_password) {

    if (this.$refs.formKennwort.validate()) {
          this.$store
        .dispatch(KENNWORT, { old_password, password, reply_password })
     }
    }
  }
};
</script>